import React from 'react'
import { Link } from 'gatsby'

export default ({ program }) => {
  const slug = `/program/${program.slug}` || '/'
  const { fullProgramName, availableMethodsOfStudy, degree } = program
  return (
    <div className="container">
      <h4>
        <Link to={slug}>
          <span>{fullProgramName}</span>
        </Link>
      </h4>
      <p className="degree">{degree}</p>
      <p className="method-of-study">
        {availableMethodsOfStudy && availableMethodsOfStudy.map((tag) => <span key={tag}>{tag}</span>)}
      </p>
    </div>
  )
}
