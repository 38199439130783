import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { Layout } from 'components/common'
import SEO from 'components/common/SEO'
import Cta from 'components/content-modules/CallToAction'
import { ModalContainer } from 'reoverlay'

import { usePartnerData } from 'hooks/usePartnerData'
import Container from 'components/Container'
import ProgramPreview from 'components/ProgramPreview'
import CoreValues from 'components/CoreValues'
import '../styles/sass/styles.scss'
import { useGlobalUpdateContext } from '../store'

const ListComponent = ({ data }) => (
  <div id="program-list" className="wrapper centered">
    {
      // sort by degree name alphabetically
      data.map((item, index) =>
        // if item.programs is empty, don't render
        item.programs.length > 0 ? (
          <div className="programs" key={index}>
            <h3>{item.title}</h3>
            <div className="list-head">
              <span>Program</span>
              <span>Degree</span>
              <span>Learning Mode</span>
            </div>
            <ul className="no-list school-program-list">
              {item.programs &&
                item.programs
                  .sort((a, b) => a.fullProgramName.localeCompare(b.fullProgramName))
                  .map((program, i) => (
                    <li key={i} className={`program ${program.slug}`}>
                      <ProgramPreview program={program} />
                    </li>
                  ))}
            </ul>
          </div>
        ) : null
      )
    }
  </div>
)

const ProgramIndex = ({ data }) => {
  const schools = data.undergradSchools.edges.map((eachVal) => eachVal.node)
  const gradPrograms = data.allContentfulProgram.nodes
  const isGraduate = data.site.siteMetadata.degreeLevel === 'Graduate'
  // alphabetize the grad programs
  // gradPrograms.sort((a, b) => a.fullProgramName.localeCompare(b.fullProgramName))
  const dispatch = useGlobalUpdateContext()
  useEffect(() => {
    dispatch({ type: 'SET_SLUG', payload: 'none-selected' })
    dispatch({ type: 'SET_PROGRAM_DETAIL_URL', payload: null })
    dispatch({
      type: 'SET_DEGREE_LEVEL',
      payload: isGraduate ? 'Graduate' : 'Undergraduate',
    })
  }, [dispatch, isGraduate])

  const doctorates = ['DEng', 'PhD', 'EdD', 'DrPH']
  const masters = ['MEng', 'MS', 'MBA', 'MEd', 'MA', 'MCRP', 'MSW', 'MPH', 'MArch', 'MAT', 'MLA/MS']
  const certiificates = ['Cert', 'CASPM']
  // map over the grad programs and create a new array of objects where program.degree is one of doctorates array, then sort by program.degree
  const doctoratePrograms = gradPrograms.filter((program) => doctorates.includes(program.degree))
  // map over the grad programs and create a new array of objects where program.degree is one of masters array
  const masterPrograms = gradPrograms.filter((program) => masters.includes(program.degree))
  // map over the grad programs and create a new array of objects where program.degree is one of certiificates array
  const certificatePrograms = gradPrograms.filter((program) => certiificates.includes(program.degree))

  // join the doctorate and master arrays and group them by title of "Doctorates", "Masters", "Certificates"
  const groupedGradProgramsArray = [
    {
      title: 'Doctorates',
      programs: doctoratePrograms,
    },
    {
      title: 'Masters',
      programs: masterPrograms,
    },
    {
      title: 'Certificates',
      programs: certificatePrograms,
    },
  ]
  // GATSBY_DEGREE_LEVEL='Undergraduate' set in enviroment variable
  const undergradSchoolsAndDegrees = schools.map((school) => {
    // if school.programs is not null then filter out the program.degreeLevel that equals data.site.siteMetadata.degreeLevel and return school
    if (school.program) {
      school.programs = school.program.filter((program) => program.degreeLevel === data.site.siteMetadata.degreeLevel)
      return school
    }
    return null
  })

  const { shortName, legalName } = usePartnerData()
  return (
    <Layout className="all-programs">
      <SEO title={data.site.siteMetadata.title} description={data.site.siteMetadata.description} />
      {isGraduate ? <SEO title="Graduate Programs" /> : <SEO title="Undergraduate Programs" />}
      <div className="hero">
        <StaticImage src="./../assets/morgan-state-hero-2024.jpg" placeholder="blurred" alt={`${shortName} Programs`} />
        <div className="hero-content">
          <h1>
            Discover {isGraduate ? 'Graduate' : 'Undergraduate'} Programs at {shortName}
          </h1>
          <Cta buttonText="Request Information">Request Information</Cta>
        </div>
      </div>
      <Container constraints="center" className="page-intro">
        <h2>Growing the Future, Leading the World</h2>
        <p>
          The {isGraduate ? 'School of Graduate Studies' : 'Undergraduate School'} at {legalName} will prepare you to be
          competitive in the real world. If you are looking for professors who care, world-class and hands-on
          experiences that grow your career, {shortName} is it. From 1964 to now, we have created a community of
          high-achieving scholars that lead the way in research, service, and their careers.
        </p>
        <p>
          With our flexible, multidisciplinary programs in both STEM and non-STEM fields, we give students like you the
          foundation for success. You will collaborate closely with your professors and peers, all while gaining
          expertise in your field. Engage in impactful research, career-minded internships, and other rewarding learning
          opportunities that shape your future.
        </p>
        <p>
          Get the education you need for today’s most sought-after jobs and careers. At {shortName}, we will support
          your growth and show you that the possibilities are endless.
        </p>
        <ul className="square">
          <li>Improve your skills</li>
          <li>Gain specialized knowledge</li>
          <li>Find success in your career</li>
          <li>Achieve your wildest dreams</li>
        </ul>
      </Container>
      <CoreValues />
      <Container id="programs">
        <div className="intro">
          <div className="wrapper centered">
            <h2>Find Your {isGraduate ? 'School of Graduate Studies' : 'Undergraduate'} Program</h2>
            <p>
              With more than 50 programs leading to graduate degrees, you can study what really matters. Whether you are
              pursuing an {isGraduate ? 'MA or MS, a specialized Master’s degree, or a PhD, ' : 'BS or BFA '} a{' '}
              {shortName} education delivers results. Get the experience of a lifetime that combines academic
              enhancement with professional growth. Explore our wide range of graduate programs offered on campus and
              online.
            </p>
          </div>
        </div>
        {isGraduate ? (
          <ListComponent data={groupedGradProgramsArray} />
        ) : (
          <ListComponent data={undergradSchoolsAndDegrees} />
        )}
      </Container>
      <Container className="cta-section">
        <div className="cta-content narrow">
          <p>
            Take the next step in your academic and professional career with a{' '}
            {isGraduate ? 'graduate' : 'undergraduate'} degree from <strong>Pursue your future&ndash;today.</strong>
          </p>
          <Cta buttonText="Request Information">Request Information</Cta>
        </div>
      </Container>
      <ModalContainer />
    </Layout>
  )
}

export default ProgramIndex

export const pageQuery = graphql`
  query ProgramIndexQuery {
    site {
      siteMetadata {
        title
        degreeLevel
        description
      }
    }
    undergradSchools: allContentfulSchool(filter: { partner: { name: { eq: "Morgan State" } } }) {
      edges {
        node {
          id
          title
          program {
            ...ProgramDetailsFragment
          }
        }
      }
    }
    allContentfulProgram(filter: { degreeLevel: { eq: "Graduate" }, partner: { name: { eq: "Morgan State" } } }) {
      nodes {
        ...ProgramDetailsFragment
      }
    }
  }

  fragment ProgramDetailsFragment on ContentfulProgram {
    id
    slug
    availableMethodsOfStudy
    degree
    degreeLevel
    programDetailUrl
    fullProgramName
  }
`
