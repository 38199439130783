import React, { useState } from 'react'
import VALUES_DATA from './ValuesData'

const CoreValues = () => {
  const [coreValue, setCoreValue] = useState('Leadership')
  return (
    <section className="core-values">
      <nav className="values-buttons">
        <div className="buttons-container">
          {VALUES_DATA.map(({ title }, index) => (
            <button
              key={index}
              type="button"
              onClick={() => setCoreValue(title)}
              className={coreValue === title ? 'active' : undefined}
            >
              {title}
            </button>
          ))}
        </div>
      </nav>

      <div className="coreValueContainer">
        {VALUES_DATA.map(({ title, description, imageComponent }, index) =>
          coreValue === title ? (
            <div key={index}>
              {imageComponent}
              <div className="value-content">
                <h3>{title}</h3>
                <p className="description">{description}</p>
              </div>
            </div>
          ) : null
        )}
      </div>
    </section>
  )
}

export default CoreValues
