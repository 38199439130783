import { StaticImage } from 'gatsby-plugin-image'

export default [
  {
    title: 'Leadership',
    description:
      'Leadership is at the heart of all we do, guiding our academics and career-related opportunities. In 2019, we produced the most Fulbright Scholars of any HBCU, and we continue to lead the way for awarding advanced degrees to African Americans. Make history with Morgan—both inside the classroom and out in the world.',
    imageComponent: (
      <StaticImage src="../../assets/leadership-2.jpg" placeholder="Blurred" alt="" layout="fullWidth" as="figure" />
    ),
  },
  {
    title: 'Innovation',
    description:
      'Honesty, ethics, and accountability are important to us. Morgan’s President regularly holds town hall meetings to communicate with the university community. Our leadership listens intently and takes action because we strive to meet student needs. Your opinions matter here.',
    imageComponent: (
      <StaticImage src="../../assets/innovation.jpg" placeholder="Blurred" alt="" layout="fullWidth" as="figure" />
    ),
  },
  {
    title: 'Integrity',
    description:
      'Honesty, ethics, and accountability are important to us. Morgan’s President regularly holds town hall meetings to communicate with the university community. Our leadership listens intently and takes action because we strive to meet student needs. Your opinions matter here.',
    imageComponent: (
      <StaticImage src="../../assets/integrity.jpg" placeholder="Blurred" alt="" layout="fullWidth" as="figure" />
    ),
  },
  {
    title: 'Diversity',
    description:
      'Honesty, ethics, and accountability are important to us. Morgan’s President regularly holds town hall meetings to communicate with the university community. Our leadership listens intently and takes action because we strive to meet student needs. Your opinions matter here.',
    imageComponent: (
      <StaticImage src="../../assets/diversity.jpg" placeholder="Blurred" alt="" layout="fullWidth" as="figure" />
    ),
  },
  {
    title: 'Excellence',
    description:
      'Honesty, ethics, and accountability are important to us. Morgan’s President regularly holds town hall meetings to communicate with the university community. Our leadership listens intently and takes action because we strive to meet student needs. Your opinions matter here.',
    imageComponent: (
      <StaticImage src="../../assets/excellence.jpg" placeholder="Blurred" alt="" layout="fullWidth" as="figure" />
    ),
  },
  {
    title: 'Respect',
    description:
      'Honesty, ethics, and accountability are important to us. Morgan’s President regularly holds town hall meetings to communicate with the university community. Our leadership listens intently and takes action because we strive to meet student needs. Your opinions matter here.',
    imageComponent: (
      <StaticImage src="../../assets/respect.jpg" placeholder="Blurred" alt="" layout="fullWidth" as="figure" />
    ),
  },
]
